<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-info mb-2 mr-2"
              data-toggle="modal"
              data-target="#addRecordModal"
            >
              <i class="fa fa-plus"></i>
            </button>

            <button
              type="button"
              class="btn btn-primary mb-2 mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-4 mt-4">
              <table
                id="OUTSTANDING_OUTSTANDING_CALLS"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Elderly</th>
                    <th>Elderly Phone</th>
                    <th>Sponsor</th>
                    <th>Sponsor Phone</th>
                    <th>Invoice</th>
                    <th>Invoice Status</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Update</th>
                    <th>Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in logs" :key="log.id">
                    <td>{{ log.prescription.elderly_name }}</td>
                    <td>{{ log.prescription.elderly_phone }}</td>
                    <td>{{ log.prescription.sponsor_name }}</td>
                    <td>{{ log.prescription.sponsor_phone }}</td>
                    <td>{{ log.prescription.invoice.invoice_no }}</td>
                    <td>
                      <span
                        class="btn btn-success"
                        v-if="log.prescription.status == 'paid and delivered'"
                        >Paid and Delivered</span
                      >
                      <span
                        class="btn btn-info"
                        v-if="log.prescription.status == 'paid not delivered'"
                        >Paid and not delivered</span
                      >

                      <span
                        class="btn btn-success"
                        v-if="
                          log.prescription.status == 'not paid but delivered'
                        "
                        >Not paid but delivered</span
                      >

                      <span
                        class="btn btn-danger"
                        v-if="
                          log.prescription.status == 'not paid not delivered'
                        "
                        >Not paid not delivered</span
                      >
                    </td>
                    <td>{{ formatMoney(log.prescription.overall_total) }}</td>
                    <td>
                      <span
                        v-if="log.status == 'responded'"
                        class="btn btn-success"
                        >Responded</span
                      >
                      <span
                        v-if="log.status == 'unreachable'"
                        class="btn btn-info"
                        >Unreachable</span
                      >
                      <span v-if="log.status == 'retry'" class="btn btn-dark"
                        >Retry</span
                      >
                      <span
                        v-if="log.status == 'declined'"
                        class="btn btn-danger"
                        >Declined</span
                      >
                    </td>
                    <td>{{ log.update }}</td>
                    <td>{{ formatDate(log.created_at) }}</td>

                    <td>
                      <div class="btn-group">
                        <!-- <button type="button" class="btn btn-warning btn-sm" @click="startEditAction(log)"> <i class="fa fa-edit"></i> </button>   -->
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="startDeleteAction(log.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Add new call record -->
      <div
        class="modal fade"
        id="addRecordModal"
        role="dialog"
        aria-labelledby="addRecordModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="mr-auto">
                <small class="modal-title" id="addRecordModalLabel"
                  >Create Follow up Record
                </small>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-12">
                  <label>Patient Name</label>
                  <Select2
                    style="width: 100%"
                    v-model="patient"
                    :options="patientNames"
                    @change="patientSelected()"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="patientPhone">Patient Phone</label>
                  <input
                    type="text"
                    v-model="selectedPatient.phone"
                    class="form-control"
                    readonly
                    id="patientPhone"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="SponsorName">Sponsor Name</label>
                  <input
                    type="text"
                    v-model="sponsor.name"
                    class="form-control"
                    readonly
                    id="SponsorName"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="SponsorPhone">Sponsor Phone</label>
                  <input
                    type="text"
                    v-model="sponsor.phone"
                    class="form-control"
                    readonly
                    id="SponsorPhone"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="SponsorEmail">Sponsor Email</label>
                  <input
                    type="text"
                    v-model="sponsor.email"
                    class="form-control"
                    readonly
                    id="SponsorEmail"
                  />
                </div>

                <div class="p-2 text-center col-12" v-if="isLoadingRecords">
                  <b-card class="">
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="67%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                    <b-skeleton animation="wave" width="82%"></b-skeleton>
                    <b-skeleton animation="wave" width="65%"></b-skeleton>
                    <b-skeleton animation="wave" width="90%"></b-skeleton>
                    <b-skeleton animation="wave" width="95%"></b-skeleton>
                    <b-skeleton type="button"></b-skeleton>
                  </b-card>
                </div>

                <div class="form-group col-12" v-else>
                  <label for="record">Drug/Prescription Record</label>
                  <select
                    v-model="record"
                    @change="recordSelected()"
                    class="form-control"
                    id="record"
                  >
                    <option
                      v-for="record in records"
                      :key="record.id"
                      :value="record.id"
                    >
                      {{ formatMoney(record.overall_total) }} billed on
                      {{ formatFancyDate(record.created_at) }}
                    </option>
                  </select>
                </div>

                <div
                  v-if="Object.keys(records).length != 0"
                  class="form-group col-12"
                >
                  <label for="update">Update</label>
                  <textarea
                    class="form-control"
                    id="update"
                    v-model="update"
                    rows="2"
                  ></textarea>
                </div>

                <div
                  v-if="Object.keys(records).length != 0"
                  class="col-12 col-md-6 mt-2"
                >
                  <p>Status</p>
                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-success"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="responded"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Responded</span>
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-info"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="unreachable"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Unreachable</span>
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-dark"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="retry"
                        v-model="status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Retry</span>
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-danger"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        v-model="status"
                        value="declined"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Declined</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-dark ml-auto" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
              <button
                type="submit"
                class="mt-4 mb-4 btn btn-primary"
                @click.prevent="saveLog()"
                :disabled="loadingSave"
              >
                <b-spinner small v-if="loadingSave"></b-spinner>
                <span v-else>Save <i class="fa fa-paper-plane"></i> </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit call record -->
      <div
        class="modal fade"
        id="editRecordModal"
        role="dialog"
        aria-labelledby="editRecordModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="mr-auto">
                <small class="modal-title" id="editRecordModalLabel"
                  >Edit Follow up Record
                </small>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-12">
                  <label>Patient Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="toEdit.prescription.elderly_name"
                    readonly
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="patientPhone">Patient Phone</label>
                  <input
                    type="text"
                    v-model="toEdit.prescription.elderly_phone"
                    class="form-control"
                    readonly
                    id="patientPhone"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="SponsorName">Sponsor Name</label>
                  <input
                    type="text"
                    v-model="toEdit.prescription.sponsor_name"
                    class="form-control"
                    readonly
                    id="SponsorName"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="SponsorPhone">Sponsor Phone</label>
                  <input
                    type="text"
                    v-model="toEdit.prescription.sponsor_phone"
                    class="form-control"
                    readonly
                    id="SponsorPhone"
                  />
                </div>

                <div class="form-group col-12 col-md-6">
                  <label for="SponsorEmail">Sponsor Email</label>
                  <input
                    type="text"
                    v-model="toEdit.prescription.sponsor_email"
                    class="form-control"
                    readonly
                    id="SponsorEmail"
                  />
                </div>

                <div class="form-group col-12">
                  <label for="record">Drug/Prescription Record</label>
                  <p>
                    {{ formatMoney(toEdit.prescription.overall_total) }} billed
                    on {{ formatFancyDate(toEdit.prescription.created_at) }}
                  </p>
                </div>

                <div class="form-group col-12">
                  <label for="update">Update</label>
                  <textarea
                    class="form-control"
                    id="update"
                    v-model="toEdit.update"
                    rows="2"
                  ></textarea>
                </div>

                <div class="col-12 col-md-6 mt-2">
                  <p>Status</p>
                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-success"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="responded"
                        v-model="toEdit.status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Responded</span>
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-info"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="unreachable"
                        v-model="toEdit.status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Unreachable</span>
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-dark"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        value="retry"
                        v-model="toEdit.status"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Retry</span>
                    </label>
                  </div>

                  <div class="n-chk">
                    <label
                      class="new-control new-radio new-radio-text radio-danger"
                    >
                      <input
                        type="radio"
                        class="new-control-input"
                        v-model="toEdit.status"
                        value="declined"
                      />
                      <span class="new-control-indicator"></span
                      ><span class="new-radio-content">Declined</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-dark ml-auto" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Close
              </button>
              <button
                type="submit"
                class="mt-4 mb-4 btn btn-primary"
                @click.prevent="completeEdit()"
                :disabled="loadingEdit"
              >
                <b-spinner small v-if="loadingEdit"></b-spinner>
                <span v-else
                  >Save Changes <i class="fa fa-paper-plane"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Search Modal -->
      <div
        class="modal fade"
        id="searchModal"
        role="dialog"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="dateSearch">Date Search</label>
                    <select
                      class="form-control"
                      v-model="search"
                      id="dateSearch"
                    >
                      <option value="all">All</option>
                      <option value="thisweek">This Week (From Monday)</option>
                      <option value="pastweek">Past Week (Last 7 days)</option>
                      <option value="lastmonth">Last Month</option>
                      <option value="thismonth">This Month</option>
                      <option value="thisyear">This Year</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="search == 'custom'">
                    <label>Select a range</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="date"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        @on-close="handlePicker()"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSearch()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete  -->
      <div
        class="modal fade"
        id="deleteModal"
        role="dialog"
        aria-labelledby="editContactModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <i class="fa fa-close" data-dismiss="modal"></i>
              <div class="add-contact-box">
                <div class="add-contact-content">
                  <div class="text-center">
                    <p class="text-danger">
                      Are you sure you want to delete this Record?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">Cancel</button>

              <button
                @click.prevent="completeDelete()"
                class="btn btn-danger text-white"
                :disabled="loadingDelete"
              >
                <b-spinner small v-if="loadingDelete"></b-spinner>
                <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import $ from "jquery";
import _ from "lodash";
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { DatePickerConfig } from "@/models/datepicker.js";

export default {
  name: "OutstandingCalls",
  components: {
    Select2,
  },

  data() {
    return {
      loading: false,
      loadingSave: false,
      loadingDelete: false,
      isLoadingRecords: true,

      patient: "",
      patients: {},
      patientNames: [],
      selectedPatient: {},
      sponsor: {},

      record: "",
      records: {},
      selectedRecord: {},

      toDelete: "",

      toEdit: {
        prescription: {},
      },
      loadingEdit: false,

      update: "",
      status: "",

      search: "thismonth",
      logs: {},

      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",
    };
  },

  mounted() {
    this.fetchRecords();
    this.fetchPatients();
  },

  methods: {
    initTable() {
      var dt = $("#OUTSTANDING_OUTSTANDING_CALLS").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([5]).visible(false);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchRecords();
        $("#searchModal").modal("hide");
      }
    },

    fetchPatients() {
      this.loading = true;
      gerohttp
        .get(geroendpoints.FETCH_ALL_PATIENTS)
        .then((response) => {
          this.loading = false;
          this.patients = response;
          this.patientNames = this.patients.map((x) => {
            return x.firstname + " " + x.lastname;
          });
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    fetchRecords() {
      this.loading = true;
      http
        .post(endpoints.ALL_OUTSTANDING_CALLS_AND_FOLLOWUP, {
          search: this.search,
          customStart: this.customStart,
          customEnd: this.customEnd,
        })
        .then((response) => {
          this.logs = response;
          this.loading = false;
          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    patientSelected() {
      var split = this.patient.split(" ");
      var temp = null;

      if (split.length == 2) {
        temp = this.patients.filter((x) => {
          return x.firstname == split[0] && x.lastname == split[1];
        });
      } else if (split.length == 3) {
        temp = this.patients.filter((x) => {
          return (
            x.firstname == `${split[0]} ${split[1]}` && x.lastname == split[2]
          );
        });

        if (typeof temp == "undefined" || temp.length == 0) {
          temp = this.patients.filter((x) => {
            return (
              x.firstname == split[0] && x.lastname == `${split[1]} ${split[2]}`
            );
          });
        }

        if (typeof temp == "undefined" || temp.length == 0) {
          console.log(`${split[0]} ${split[1]} ${split[2]}`);
          temp = this.patients.filter((x) => {
            return (
              x.firstname == split[0] &&
              x.midname == split[1] &&
              x.lastname == split[2]
            );
          });
        }

        if (typeof temp == "undefined" || temp.length == 0) {
          temp = this.patients.filter((x) => {
            return x.firstname == `${split[0]} ${split[1]} ${split[2]}`;
          });
        }
      }

      this.selectedPatient = temp[0];
      this.sponsor = temp[0].user;

      this.isLoadingRecords = true;
      this.record = "";
      this.records = {};
      this.recordNames = [];
      this.fetchPrescriptionRecords();
    },

    fetchPrescriptionRecords() {
      http
        .get(
          endpoints.UNPAID_RECORDS_FOR_ELDERLY.replace(
            ":id",
            this.selectedPatient.id
          )
        )
        .then((response) => {
          console.log(response);
          this.isLoadingRecords = false;
          this.records = response;
        })
        .catch((error) => {
          this.isLoadingRecords = false;
          console.log(error);
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    recordSelected() {
      var temp = this.records.filter((x) => {
        return x.id == this.record;
      });
      this.selectedRecord = temp[0];
    },

    saveLog() {
      if (_.isEmpty(this.selectedPatient) || _.isEmpty(this.selectedRecord)) {
        this.$toast.error("Missing Input fields. Please try again.");
      } else {
        this.loadingSave = true;
        http
          .post(endpoints.NEW_OUTSTANDING_CALL_AND_FOLLOW_UP, {
            prescription_id: this.selectedRecord.id,
            status: this.status,
            update: this.update,
          })
          .then((response) => {
            console.log(response);
            this.$toast.success(response);
            this.loadingSave = false;
            this.record = "";
            this.update = "";
            this.status = "";
            $("#addRecordModal").modal("hide");
            this.fetchRecords();
            if (this.status == "proceed") {
              setTimeout(() => {
                this.$toast.info("Notification sent to medical services");
              }, 2000);
            }
          })
          .catch((error) => {
            this.loadingSave = false;
            this.$toast.error(error.response?.data?.message || error.response?.message);
            $("#addRecordModal").modal("hide");
          });
      }
    },

    startEditAction(x) {
      this.toEdit = x;
      $("#editRecordModal").modal("show");
    },

    completeEdit() {
      this.loadingEdit = true;
      http
        .put(endpoints.EDIT_OUTSTANDING_CALL_AND_FOLLOWUP, {
          call_id: this.toEdit.id,
          prescription_id: this.toEdit.prescriptions_id,
          status: this.toEdit.status,
          update: this.toEdit.update,
        })
        .then((response) => {
          this.$toast.success(response);
          this.loadingEdit = false;
          $("#editRecordModal").modal("hide");
          this.fetchRecords();
        })
        .catch((error) => {
          console.log(error);
          this.loadingEdit = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    startDeleteAction(x) {
      this.toDelete = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(
          endpoints.DELETE_OUTSTANDING_CALL_AND_FOLLOWUP.replace(
            ":id",
            this.toDelete
          )
        )
        .then((response) => {
          this.$toast.success(response);
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.fetchRecords();
        })
        .catch((error) => {
          console.log(error);
          this.loadingDelete = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },
  },
};
</script>

<style>
.select2-container {
  width: 90% !important;
}
.new-control {
  font-weight: 400;
  font-size: 15px;
}
</style>
